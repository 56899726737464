<template>
  <button @click="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)" class="p0 inline-flex middle-xs bg-cl-transparent brdr-none action h5 pointer cl-secondary" aria-label="Add to wishlist" type="button" data-testid="addToWishlist">
    <slot>
      <div class="flex middle-xs center-xs serce-box brdr-2" :class="isOnWishlist ? 'brdr-cl-main' : 'brdr-cl-main'">
        <i :class="isOnWishlist ? 'icon-favourite-fill cl-main fs20': 'h3 cl-main icon-favourite-stroke'" />
      </div>
      <!-- <template v-if="!isOnWishlist">
        {{ $t('Add to favorite') }}
      </template>
      <template v-else>
        {{ $t('Remove') }}
      </template> -->
    </slot>
  </button>
</template>

<script>
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'

export default {
  mixins: [ IsOnWishlist, AddToWishlist, RemoveFromWishlist ],
  methods: {
    addProductToWhishlist (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been added to wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') },
        action2: { label: i18n.t('Go to wishlist'), action: 'toWishlist' }
      }, { root: true })
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>

<style lang="scss" scoped>
.serce-box {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  @media (max-width: 767px) {
    height: 38px;
    width: 38px;
  }
}
</style>
