<template>
  <div class="wishlist cl-accent">
    <div class="whislist-header row middle-xs between-xs bg-cl-white">
      <div class="hidden-xs pointer" v-if="productsInWishlist.length" @click="clearWishlist">
        <p class="weight-600 h6 m0 cl-black">
          {{ $t('Wyczyść listę życzeń') }}
        </p>
      </div>
      <div class="visible-xs pointer">
        <img class="flex" alt="Kultowy.pl Logo" src="/assets/kultowy-logo.svg" height="40" width="65">
      </div>
      <h2 v-if="productsInWishlist.length" class="h3 py15 m0 cl-black">
        {{ $t('Wishlist') }}
      </h2>
      <h4 v-if="!productsInWishlist.length" class="h3 empty-wishlist m0 cl-black">
        {{ $t('Your wishlist is empty.') }}
      </h4>
      <i class="icon-zamknij cl-main weight-900 h3" @click="closeWishlist" />
    </div>
    <div v-if="productsInWishlist.length" @click="clearWishlist" class="visible-xs pl10 pt15">
      <p class="weight-600 h6 m0 cl-main">
        {{ $t('Wyczyść listę życzeń') }}
      </p>
    </div>
    <div v-if="!productsInWishlist.length" class="ml30 mt50 cl-black">
      {{ $t("Don't hesitate and") }}
      <router-link class="cl-main browse-link" :to="localizedRoute('/')" @click.native="closeWishlist">
        {{ $t('browse our catalog') }}
      </router-link>
      {{ $t('to find something beautiful for You!') }}
    </div>
    <ul v-show="$device.isMobile" class="products">
      <product v-for="wishlistProduct in productsInWishlist" :key="wishlistProduct.id" :product="wishlistProduct" />
    </ul>
    <product-listing v-show="!$device.isMobile" class="m0" columns="wishlist" :filter-toogle="true" :products="productsInWishlist" />
    <div class="mb50 env-padding-bottom">
      <div class="row m0 py15 center-xs">
        <div class="col-xs-12 col-sm-6 p0 align-center">
          <h3 class="m0 mb5 cl-black">
            {{ $t('My Recently viewed products') }}
          </h3>
        </div>
      </div>
      <div class="row m0">
        <div class="col-xs-12" v-if="sliceRecentlyView && sliceRecentlyView.length > 0">
          <product-listing :recently-view="true" class="m0" columns="wishlist" :filter-toogle="true" :products="sliceRecentlyView" />
        </div>
        <div class="col-xs-12 h4 p0 ml30 cl-black" v-else>
          <p>{{ $t('No products yet') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wishlist from '@vue-storefront/core/compatibility/components/blocks/Wishlist/Wishlist'
import Product from 'theme/components/core/blocks/Wishlist/Product'
import ProductListing from 'theme/components/core/ProductListing.vue'
import RecentlyViewed from '@vue-storefront/core/modules/recently-viewed/components/RecentlyViewed'

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  components: {
    Product,
    ProductListing
  },
  computed: {
    sliceRecentlyView () {
      if (this.items && this.items.length > 0) {
        let temp = Array.from(new Set(this.items.slice(0, 9)))
        return temp
      } else {
        return []
      }
    }
  },
  methods: {
    clearWishlist () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Are you sure you would like to remove all the items from the wishlist?'),
        action1: { label: this.$t('OK'),
          action: () => {
            this.$store.dispatch('wishlist/clear')
          }
        },
        action2: { label: this.$t('Cancel'), action: 'close' },
        hasNoTimeout: true
      })
    }
  },
  mixins: [Wishlist, RecentlyViewed]
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
.whislist-header {
  padding: 0 40px;
  @media (max-width: 767px) {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 0 15px;
  }
}
.empty-wishlist {
  padding-top: 45px;
  @media (max-width: 767px) {
    font-size: 18px;
    padding: 15px 0;
  }
}
.products {
  padding-left: 10px;
}
</style>
