<template>
  <div id="product-listing" class="product-listing row start-xs start-md">
    <div
      v-for="product in products"
      :key="product.id"
      class="flex product-listing__wrapper"
      :class="columnsClass()"
    >
      <product-tile
        :product="product"
        :list="currentCategoryName"
        :columns="columns"
        :recently-view="recentlyView"
        :omnibus-price="omnibusPriceMap[product.sku]"
      />
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import {
  fetchOmnibusPriceList,
  getProductsOnSaleSkus,
  proccessPrices
} from 'theme/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

let lastHero = 0
export default {
  name: 'ProductListing',
  data () {
    return {
      omnibusPriceMap: {}
    }
  },
  components: {
    ProductTile
  },
  props: {
    currentCategoryName: {
      type: String,
      required: false,
      default: 'catalog'
    },
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    filterToogle: {
      type: Boolean,
      default: false
    },
    recentlyView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    products: function (newVal, oldVal) {
      if ((newVal.length !== oldVal.length) || (newVal.length === 20 && oldVal.length === 20)) {
        this.updateOmnibusPriceMap(newVal.length - oldVal.length)
      }
    }
  },
  computed: {
    currencyCode () {
      return currentStoreView().i18n.currencyCode
    }
  },
  beforeMount () {
    this.updateOmnibusPriceMap()
  },
  methods: {
    async updateOmnibusPriceMap (lengthDiff) {
      if (!(this.products && this.products.length)) return

      const newProducts = this.products.slice(-lengthDiff)
      if (!newProducts || !newProducts.length) return

      const productsInSaleSkus = getProductsOnSaleSkus(newProducts)
      if (!productsInSaleSkus || !productsInSaleSkus.length) return

      let omnibusPriceMap = await fetchOmnibusPriceList(productsInSaleSkus, this.currencyCode)
      if (!omnibusPriceMap) return
      omnibusPriceMap = proccessPrices(omnibusPriceMap)
      this.omnibusPriceMap = Object.assign({}, omnibusPriceMap, this.omnibusPriceMap)
    },
    columnsClass () {
      return this.columns === 'wishlist'
        ? 'col-md-4 col-xs-6' : this.columns === 'on'
          ? this.filterToogle
            ? 'col-xs-6 col-md-4 col-lg-3'
            : 'col-xs-6 col-md-3 col-lg-2dot4'
          : 'col-xs-12'
    },
    wide (isOnSale, isNew, index) {
      let deltaCondition = index > 0 && ((index - 1) - lastHero) % 2 === 0
      // last image always shouldn't be big, we also need to count from last promoted to check if it will look ok
      let isHero = ((isOnSale === '1' || isNew === '1') && deltaCondition) || (index === this.products.length - 1 && (index - lastHero) % 2 !== 0)
      if (isHero) {
        lastHero = index
      }
      return isHero ? 'col-xs-12' : 'col-xs-6'
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-listing {
    @media (max-width: 767px) {
      margin: 0;
      &__wrapper {
        justify-content: center;
      }
    }
  }
</style>
